<template>
  <v-container fluid class="pt-0">
    <div class="text-h5">用户组列表</div>
    <div class="mt-1 mb-5" style="border-bottom: 1px solid #ddd;"></div>

    <div style="display: flex">
      <div style="flex: 1">
        <v-text-field
          v-model="search.name"
          dense
          label="输入用户组名查询"
          solo
        ></v-text-field>
      </div>
      <div>
        <v-btn color="primary" @click="doGet(1)">查询</v-btn>
      </div>
    </div>
    <div
      style="border-bottom: 1px solid #ddd;margin-bottom: 30px;margin-top: -5px"
    ></div>
    <v-simple-table v-if="refresh">
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">分组名称</th>
            <th class="text-left">操作</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(item, index) in table_data"
            :key="item.name"
            :style="index % 2 === 0 ? 'background: rgb(242,242,242)' : ''"
          >
            <td>{{ item.name }}</td>
            <td>
              <v-btn color="primary" small @click="editItem(item.id)"
                >编辑</v-btn
              >
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>

    <v-btn
      color="primary"
      dark
      small
      style="margin-top: 10px"
      @click="openAddBox"
    >
      新增
    </v-btn>
    <v-dialog v-model="dialog" width="500">
      <v-card>
        <v-card-title class="headline grey lighten-2">
          添加用户组
        </v-card-title>

        <div style="padding: 10px">
          <v-text-field
            v-model="newName"
            dense
            label="Solo"
            placeholder="请输入用户组名称"
            solo
          ></v-text-field>
        </div>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="okAdd">
            确定
          </v-btn>
          <v-btn color="primary" text @click="dialog = false">
            取消
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <paginations
      :page="search.page"
      :page-size="search.pagesize"
      :total="total"
      style="float: right"
      @change="(e) => doGet(e)"
    ></paginations>
  </v-container>
</template>

<script>
import { addGroup, getGroupList } from "@/api/usergroups";
import paginations from "@/components/paginations";

export default {
  name: "usergroups",
  mounted() {
    this.doGet();
  },
  methods: {
    doGet(page = this.search.page) {
      this.search.page = page;
      console.log("search..", this.search);
      getGroupList({ ...this.search }).then((res) => {
        console.log(res);
        this.table_data = res.results;
        this.total = res.count;
      });
    },
    openAddBox() {
      this.newName = "";
      this.dialog = true;
    },
    okAdd() {
      if (this.newName === "") {
        this.$message("用户组名不能为空");
        return;
      }
      addGroup({ name: this.newName }).then(() => {
        this.dialog = false;
        this.doGet();
      });
    },
    editItem(id) {
      this.$router.push({ name: "edit_group", params: { id } });
    },
  },
  data: () => ({
    newName: "",
    refresh: true,
    total: 0,
    dialog: false,
    search: {
      page: 1,
      name: "",
      pagesize: 10,
    },
    table_data: [],
  }),
  components: {
    paginations,
  },
};
</script>
<style lang="stylus">
.n-title
	font-size 32px

.checkbox >>> .v-label
	font-size 12px
	color #212529
	margin-left -10px !important
	margin-right 10px !important
</style>
